import Vue from 'vue';
import Vuetify from 'vuetify/lib/framework';

Vue.use(Vuetify);

import es from 'vuetify/lib/locale/es'


//YO LO AGREGUÉ
Vue.component('my-component', {
    methods: {
      changeLocale () {
        this.$vuetify.lang.current = 'es'
      },
    },
  })



export default new Vuetify({
//YO LO AGREGUE
    lang: {
        locales: { es },
        current: 'es',
    },

});
