<template>
  <v-app>
    <v-app-bar
      app
      elevation="6"
      src="./assets/background.jpg"
    >
      <div class="d-flex align-center">
        <v-app-bar-nav-icon v-if="userIsAuth" @click.stop="drawer = true"></v-app-bar-nav-icon>
        <v-img
          alt="Ulink"
          class="shrink mt-1 hidden-sm-and-down ml-6"
          contain
          min-width="100"
          src="./assets/ulink.png"
          width="140"
        />
        
      </div>
      <v-spacer></v-spacer>
      <h5 v-if="user != null">Usuario: {{user.email}}</h5><h5 v-if="user != null && user.role != 'anuadmin'"></h5>
      <v-spacer></v-spacer>

      <v-btn
        href="https://anukin.com"
        target="_blank"
        text
      >
        <span class="mr-2 mt-3" style="text-transform:none">Powered by</span>
        <v-img max-width="90" contain src="./assets/anukin.png"></v-img>
      </v-btn>
    </v-app-bar>


    <v-navigation-drawer app v-model="drawer" absolute temporary dark>
      <v-list-item>
        <v-list-item-content>
          <v-list-item-title class="text-h6">Menu</v-list-item-title>
          <!--<v-list-item-subtitle>subtext</v-list-item-subtitle>-->
        </v-list-item-content>
      </v-list-item>

      <v-divider></v-divider>

      <v-list dense nav>
        <v-list-item
            v-for="item in items"
            :key="item.title"
            link
            :to="item.link" 
        >
          <v-list-item-icon>
            <v-icon>{{ item.icon }}</v-icon>
          </v-list-item-icon>

          <v-list-item-content>
            <v-list-item-title>{{ item.title }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>

        <v-divider></v-divider>

        <v-list-item v-if="isAdmin">
        <v-list-item-content>
          <v-list-item-title class="text-h6">Admin</v-list-item-title>
          <!--<v-list-item-subtitle>subtext</v-list-item-subtitle>-->
        </v-list-item-content>
        </v-list-item>

        <v-list-item v-if="isAdmin" link to="/clients">
          <v-list-item-icon>
            <v-icon>mdi-account-plus</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>Clientes</v-list-item-title>
          </v-list-item-content>
        </v-list-item>

        <v-list-item v-if="isAdmin" link to="/tagsAssignation">
          <v-list-item-icon>
            <v-icon>mdi-tag-plus-outline</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>Asignación de Tags</v-list-item-title>
          </v-list-item-content>
        </v-list-item>

        <v-list-item v-if="isAdmin" link to="/anuadmins">
          <v-list-item-icon>
            <v-icon>mdi-shield-crown</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>Anuadmins</v-list-item-title>
          </v-list-item-content>
        </v-list-item>

        <v-list-item v-if="isAdmin" link to="/users">
          <v-list-item-icon>
            <v-icon>mdi-account-multiple-plus</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>Usuarios</v-list-item-title>
          </v-list-item-content>
        </v-list-item>

        <v-divider v-if="isAdmin"></v-divider>

        <v-list-item link @click.prevent="resetPassword(user.email)">
          <v-list-item-icon>
            <v-icon>mdi-form-textbox-password</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>Restablecer Contraseña</v-list-item-title>
          </v-list-item-content>
        </v-list-item>

        <v-list-item link @click.prevent="logOut">
          <v-list-item-icon>
            <v-icon>mdi-logout</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>Salir</v-list-item-title>
          </v-list-item-content>
        </v-list-item>

      </v-list>
    </v-navigation-drawer>




    <v-main class="customBackground">
      <router-view/>
    </v-main>
  </v-app>
</template>

<script>
import { mapActions, mapGetters, mapState } from 'vuex';

export default {
  name: 'App',

  data: () => ({
    items: [
        { title: 'Tags', icon: 'mdi-tag-outline', link: '/tags' },
        { title: 'Interacciones', icon: 'mdi-account-group', link: '/interactions'},
        //{ title: 'Clientes', icon: 'mdi-antenna', link: '/clients' },
    ],
    right: null,
    drawer: false,
    group: null
  }),

  computed: {
    ...mapGetters(['userIsAuth','isAdmin']),
    ...mapState(['user', 'error']),
  },

  methods: {
    ...mapActions(['logOut','resetPassword'])
  },

};
</script>

<style>
.customBackground{
  background: url('./assets/background.jpg');
  background-size: cover;
}
</style>
