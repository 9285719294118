import Vue from 'vue'
import Vuex from 'vuex'
import router from '../router/index'
import {auth} from "../firebase"
import {signInWithEmailAndPassword, sendPasswordResetEmail, signOut} from "firebase/auth"
import {storage} from '../firebase'
import {ref, deleteObject} from 'firebase/storage'
import {db} from '../firebase'
import {collection, query, onSnapshot, setDoc, doc, deleteDoc, getDoc, getDocs, Timestamp, where} from 'firebase/firestore'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    user: null,
    error: null,
    clients: [],
    interactions: [],
    interactionsCustom: [],
    tags: [],
  },
  mutations: {
    SET_USER(state, payload){
      state.user=payload
    },
    SET_ERROR(state, payload){
      state.error=payload
    },
    SET_CLIENTS(state, payload){
      state.clients.push(payload)
    },
    CLEAR_CLIENTS(state){
      state.clients=[]
    },
    SET_USERCLIENTS(state, payload){
      state.user.clients.push(payload)
    },
    CLEAR_USERCLIENTS(state){
      state.user.clients=[]
    },
    SET_INTERACTIONS(state, payload){
      state.interactions.push(payload)
    },
    CLEAR_INTERACTIONS(state){
      state.interactions=[]
    },
    SET_INTERACTIONS_CUSTOM(state, payload){
      state.interactionsCustom.push(payload)
    },
    CLEAR_INTERACTIONS_CUSTOM(state){
      state.interactionsCustom=[]
    },
    SET_TAGS(state, payload){
      state.tags.push(payload)
    },
    CLEAR_TAGS(state){
      state.tags=[]
    }
  },
  actions: {
    async logIn({commit, dispatch}, userLogin){
      try {
        const userSnapshot = await signInWithEmailAndPassword(auth, userLogin.email, userLogin.password)
        const {accessToken,email,emailVerified} = userSnapshot.user
        const docRef = doc(db, "users", email)
        const docSnap = await getDoc(docRef)
        const {clients, role} = docSnap.data()

        let user = {}
        if(role === 'user'){
          user = {accessToken,email,emailVerified,clients,role}
          router.push('/tags')
        }
        if(role === 'anuadmin'){
          const clients = []
          const q = query (collection(db, "clients"))
          const querySnapshot = await getDocs(q)
          querySnapshot.forEach(doc => {
            const clientObj = {
              clientDesc: doc.data().clientDesc,
              clientId: doc.data().clientId
            }
            clients.push(clientObj)
          })
          user = {accessToken,email,emailVerified,clients,role}
          router.push('/clients')
        }
        
        commit('SET_USER', user)
        commit('SET_ERROR', null)
        sessionStorage.setItem('user', JSON.stringify(user))
      } catch (error) {
          let message=null
          //console.log('Code: '+error.code)
          //console.log('Msg: '+error.message)
          if(error.code==='auth/user-not-found'){message='Usuario no encontrado'}
          if(error.code==='auth/wrong-password'){message='Contraseña incorrecta'}
          commit('SET_ERROR', message)
        }
    },

    async logOut({commit}){
      try {
        sessionStorage.removeItem('user')
        const resp = await signOut(auth)
        router.push('/login')
        commit('SET_USER', null)
        commit('CLEAR_CLIENTS')
        commit('CLEAR_INTERACTIONS')
        commit('CLEAR_TAGS')
      } catch (error) {
          console.log('Code: '+error.code)
          console.log('Msg: '+error.message)
      }
    },

    detectUser({commit}, user){
      commit('SET_USER', user)
    },

    async resetPassword({commit}, email){
      try {
        await sendPasswordResetEmail(auth, email)
        alert("Se ha enviado los pasos a seguir a tu correo para reiniciar la contraseña")
      } catch (error) {
        console.log('Code: '+error.code)
        console.log('Msg: '+error.message)
      }
    },

    async getTags({commit}, client){
      try {
        const tagsSnapshot = await getDocs(collection(db, `tags/${client}/list`))
        const tagsList = tagsSnapshot.docs.map(doc => doc.data())
        tagsList.forEach(item =>{
          commit('SET_TAGS', item)
        })  
      } catch (error) {
          console.log(error)
      } 
    },

    async editTag({commit}, tag){
      try {
        await setDoc(doc(db, `tags/${tag.clientId}/list`, tag.tagId), tag)
        
      } catch (error) {
          console.log(error)
      }
    },

    //Solo se ejecuta para anuadmin ya que solo el permiso administra clientes
    async getClients({commit,state}){
      try {
        const q = query(collection(db, "clients"))
        const unsuscribe = onSnapshot(q, querySnapshot =>{
          commit('CLEAR_CLIENTS')
          querySnapshot.forEach(doc =>{
            commit('SET_CLIENTS', doc.data() )
          })
        })
      } catch (error) {
        console.log(error)
      }
    },

    async getInteractions({commit, state}, filter = null){
      try {
        //console.log(state.user.clients)
        //console.log(filter)
        //console.log("one: "+!filter)
        //console.log("two: "+!!filter)
        let q = ''
        if(filter){
          //console.log("Entro a IF filter")
          const dateStartJS = new Date(filter.dateStart)
          const timezoneOffsetStart = dateStartJS.getTimezoneOffset() * 60000
          const timestampStart = new Date(dateStartJS.getTime() + timezoneOffsetStart)
          //console.log(timestampStart)
          //console.log("Start: "+Timestamp.fromDate(timestampStart))

          const dateEndJS = new Date(filter.dateEnd)
          const timezoneOffsetEnd = dateEndJS.getTimezoneOffset() * 60000
          const timestampEnd = new Date(dateEndJS.getTime() + timezoneOffsetEnd + 86399000)
          //console.log(timestampEnd)
          //console.log("End: "+Timestamp.fromDate(timestampEnd))
          if(state.user.role === 'user'){q = query(collection(db, "interactions"), where("created", ">=", timestampStart), where("created", "<=", timestampEnd), where("clientId", "in", state.user.clients.map(e => e.clientId)))}  
          else{q = query(collection(db, "interactions"), where("created", ">=", timestampStart), where("created", "<=", timestampEnd))}
        }
        else{
          if(state.user.role === 'user'){q = query(collection(db, "interactions"), where("clientId", "in", state.user.clients.map(e => e.clientId)))}
          else {q = query(collection(db, "interactions"))}
        }
          
        const unsuscribe = onSnapshot(q, querySnapshot =>{
          commit('CLEAR_INTERACTIONS')
          querySnapshot.forEach(doc =>{
            const interaction = Object.assign({}, doc.data())
            interaction.firestoreId = doc.id
            commit('SET_INTERACTIONS', interaction)
          })
        })
      } catch (error) {
        console.log(error)
      }
    },

    async getInteractionsCustom({commit, state}){
      //console.log("interactionsCustom")
      //Get All Custom Clients
      const allCustoms = []
      const customsQuery = query(collection(db, "custom"))
      const customsSnapShot = await getDocs(customsQuery)
      customsSnapShot.forEach(item => {
        allCustoms.push(item.id)
      })
      //console.log(allCustoms)

      //Get interactions per customer
      commit('CLEAR_INTERACTIONS_CUSTOM')
      allCustoms.forEach(async item => {
        const q = query(collection(db, `custom/${item}/interactions`))
        const querySnapShot = await getDocs(q)
        querySnapShot.forEach(e => {
          const interaction = Object.assign({}, e.data())
          interaction.firestoreId = e.id
          commit('SET_INTERACTIONS_CUSTOM', interaction)
        })
      })
      
    },


    async updateClient({commit,state}, client){
      try {
        //console.log(client)
        await setDoc(doc(db, "clients", client.clientId), client)
        await setDoc(doc(db, "tags", client.clientId), {})
        commit('CLEAR_USERCLIENTS', [])
        state.clients.forEach(e => {
          commit('SET_USERCLIENTS', e)
        })
        
      } catch (error) {
        console.log(error)
      }
    },

    async deleteClient({commit,state}, client){
      try {
        console.log(client)
        //DELETE DB
        //Delete tags
        const q = query(collection(db, `tags/${client.clientId}/list`))
        const querySnapshot = await getDocs(q)
        querySnapshot.forEach(async (item) => {
          console.log
          await deleteDoc(doc(db, `tags/${client.clientId}/list`, item.id))
        })
        await deleteDoc(doc(db, "tags", client.clientId))

        //Delete client
        await deleteDoc(doc(db, "clients", client.clientId))
        commit('CLEAR_USERCLIENTS', [])
        state.clients.forEach(e => {
          commit('SET_USERCLIENTS', e)
        })

        //Delete Storage
        //const clientRef = ref(storage, client.name)
        //await deleteObject(clientRef)

      } catch (error) {
        console.log(error)
      }
    },

    async deleteInteraction({commit}, interaction){
      try {
        //console.log(interaction.firestoreId)
        await deleteDoc(doc(db, "interactions", interaction.firestoreId))
      } catch (error) {
        console.log(error)
      }
    },

    async deleteInteractionCustom({commit, dispatch}, interaction){
      try {
        //console.log(interaction.firestoreId)
        await deleteDoc(doc(db, `custom/${interaction.clientId}/interactions`, interaction.firestoreId))
        //console.log(interaction)
        dispatch('getInteractionsCustom')
      } catch (error) {
        console.log(error)
      }
    }
  },
  
  modules: {
  },

  getters: {
    userIsAuth(state){
      if(state.user != null){return true}
      else{return false}
    },
    isAdmin(state){
      if(state.user){
        if(state.user.role === 'anuadmin'){return true}
        else {return false}
      }
      else{return false}
    },
  },

})
