import Vue from 'vue'
import VueRouter from 'vue-router'
import {auth} from '../firebase'


Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "home" */ '../views/Home.vue'),
    meta: {requiresAuth: true}
  },
  {
    path: '/about',
    name: 'About',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/About.vue'),
    meta: {requiresAuth: true}
  },
  {
    path: '/clients',
    name: 'Clients',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "clients" */ '../views/Clients.vue'),
    meta: {requiresAuth: true, requiresAdmin: true}
  },
  {
    path: '/interactions',
    name: 'Interactions',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "clients" */ '../views/Interactions.vue'),
    meta: {requiresAuth: true}
  },
  {
    path: '/tags',
    name: 'Tags',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "tags" */ '../views/Tags.vue'),
    meta: {requiresAuth: true}
  },
  {
    path: '/login',
    name: 'Login',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "login" */ '../views/Login.vue'),
    meta: {login: true}
  },
  {
    path: '/overallPromotion',
    name: 'OverallPromotion',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "overallPromotion" */ '../views/OverallPromotion.vue'),
    meta: {requiresAuth: true}
  },
  {
    path: '/tagsAssignation',
    name: 'TagsAssignation',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "tagsAssignation" */ '../views/TagsAssignation.vue'),
    meta: {requiresAuth: true, requiresAdmin: true}
  },
  {
    path: '/support',
    name: 'Support',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "support" */ '../views/Support.vue'),
    meta: {requiresAuth: true, requiresAdmin: true}
  },
  {
    path: '/anuadmins',
    name: 'Anuadmins',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "anuadmins" */ '../views/Anuadmins.vue'),
    meta: {requiresAuth: true, requiresAdmin: true}
  },
  {
    path: '/users',
    name: 'Users',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "users" */ '../views/Users.vue'),
    meta: {requiresAuth: true, requiresAdmin: true}
  },
  { 
    path: '/:pathMatch(.*)*', 
    name: 'NotFound', 
    component: () => import(/* webpackChunkName: "notFound" */ '../views/NotFound.vue'), 
  },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

/*router.beforeEach((to, from, next) => {
  if(to.matched.some(record => record.meta.requiresAuth)){ 
    if(auth.currentUser || sessionStorage.getItem('user')){
      next()
    }else {next({name: 'Login'})}
  }else {
    if(to.meta.login && (sessionStorage.getItem('user') || auth.currentUser )){next({name: 'Home'})}
    else{next()}
  } 
})*/


router.beforeEach((to, from, next) => {
  if(to.matched.some(record => record.meta.requiresAuth)){ 
    if(auth.currentUser || sessionStorage.getItem('user')){
      if(to.meta.requiresAdmin && sessionStorage.getItem('user')){
        const checkAdmin = JSON.parse(sessionStorage.getItem('user'))
        //console.log("checkadmin: "+checkAdmin)
        if(checkAdmin.role === 'anuadmin'){next()}
        else{next({name: 'Home'})}
      }
      else {
        next()
      }
    }else {next({name: 'Login'})}
  }else {
    if(to.meta.login && (sessionStorage.getItem('user') || auth.currentUser )){next({name: 'Home'})}
    else{next()}
  } 
})


export default router
